import React from "react"
import {useSelector, useDispatch} from "react-redux"
import { useCookies } from "react-cookie"
import SEO from "../components/seo/seo"
import Layout from "../components/layout/layout"
import AppBar from "../components/appBar/appBar"
import MainSection from "../components/mainSection/mainSection"
import SecondarySection from "../components/secondarySection/secondarySection"
import PageSection from "../components/pageSection/pageSection"
import ImageSection from "../components/imageSection/imageSection"
import Card from "../components/card/card"
import LaunchForm from "../components/launchForm/launchForm"
import FAQs from "../components/faqs/faqs"
import DonateForm from "../components/donateForm/donateForm"
import OptOut from "../components/optOut/optOut"
import IFrame from "../components/iFrame/iFrame"
import Modal from "../components/modal/modal"
import List from "../components/list/list"

import * as ACTIONS from "../state/actions"

const tcPdf = <IFrame file="../TC.pdf" title="Términos de uso"/>;
const pcPdf = <IFrame file="../PC.pdf" title="Política de privacidad"/>;
// const footerDesc = "";
const contentMaxWidth = "980px";

const joinEvvaDonate = [
    {
        id: 0,
        label: 'Open an EVVA business account. 20% of your monthly management fee will be donated by Evva automatically to businesses affected by COVID-19.'
    },
    {
        id: 1,
        label: 'Send a one time donation to your chosen businesses.',
    },
];

const share = [
    {
        id: 0,
        label: 'Tell a local store about this program if you think they need the funding.'
    },
    {
        id: 1,
        label: 'Share with your friends & family to help out, every little helps.',
    },
];

const rewarded = [
    {
        id: 0,
        label: 'Continue your support by visiting or purchasing from small businesses and receive reward & voucher from them.'
    },
    {
        id: 1,
        label: 'Nothing beats the joy of being part of the contribution and together, we will be stronger.',
    },
];

const dataFilters = [
    {
        id: "general",
        label: "General"
    },
    {
        id: "onboarding",
        label: "Onboarding"
    }
];

const dataFAQs = [
    {
        title: "Who is EVVA for?",
        description: "<p>We’re currently open to any Mexican residents who are a director of a limited company, and non-limited businesses that are structured as a partnership (Sociedades SAS, SAPI, SA de CV o SC) or sole trader.</p>",
        categories: ["general"]
    },
    {
        title: "Is EVVA a bank?",
        description: "<p>EVVA is a digital platform through which you can manage your business financial services. All financial services offered on the platform are carried out by one or more financial institutions, which are regulated and supervised by the CNBV and CONDUSEF.</p>",
        categories: ["general"]
    },
    {
        title: "What is a EVVA Business account?",
        description: "<p>EVVA business account is a low risk investment account with immediate liquidity so you can withdraw your money at any time, the funds are managed by Scotia Global Asset Management, a ScotiaBank company. For detailed information about this fund please read the following <a href='https://scotiabankfiles.azureedge.net/scotia-bank-mexico/spanish/pdf/personas/fondos-de-inversion/prospectos/Prospecto_SCOTIAG.pdf?t=1592919143996' target='_blank' rel='noopener noreferrer'>document</a></p>",
        categories: ["general"]
    },
    {
        title:"Why is the service called EVVA?",
        description: "<p>EVVA comes from our manifesto:<br /><strong>Entrepreneurship:</strong> our daily inspiration. We are focused on the entrepreneur’s financial needs so they can focus on what really matters: their business.</p><p><strong>Veracity:</strong> being transparent and direct with our customers. We are totally against hidden fees and complex pricings.</p><p><strong>Velocity:</strong> everything changes at a lightspeed pace. Being agile and adaptable is key to succeed. We craft agile financial experiences with the ultimate goal of making small businesses grow to the next level.</p><p><strong>Accessible:</strong> being inclusive. Our services must be open to all entrepreneurs that want to create an impact and contribute to its country’s economy.</p>",
        categories: ["general"]
    },
    {
        title: "What other services are available in EVVA?",
        description: "<p>With our products and allies we are developing a full suite of services for your business. Visit our <a href='/en/marketplace' target='_self' rel='noopener noreferrer'>marketplace page for more information.</a></p>",
        categories: ["general"]
    },
    {
        title: "What’s the best way to contact EVVA?",
        description: "<p>If you’re a customer Through the web app chat feature, where you can contact us during office hours.</p><p>You can also email us at <a href='mailto:susbcripciones@evvafinanzas.com' target='_blank' rel='noopener noreferrer'>susbcripciones@evvafinanzas.com</a> or ayuda@evvafinanzas.com to sort out issues or share ideas on how we can help you more.</p><p>If you’re curious drop us a line at <a href='mailto:info@evvafinanzas.com' target='_blank' rel='noopener noreferrer'>info@evvafinanzas.com</a> We’re always happy to answer questions.</p>",
        categories: ["general"]
    },
    {
        title: "How much does EVVA cost?",
        description: "<p>You pay a simple flat monthly fee, depending on how much you use EVVA and the stage of the company. Plans cost $0 for newly established companies and $175 MXN for companies already invoicing. For detailed information visit our pricing page.</p>",
        categories: ["general"]
    },
    {
        title: "What do I need for opening a business account business?",
        description: "<p>SMEs established as limited companies:</p><ul><li>CURP ID of legal representative</li><li>Front photo of the INE card of the legal representative, it must be in JPG, PNG format, you can take it with your cell phone or laptop,  ensure that it is completely clear</li><li>Photo of back of the voter card of the legal representative, it must be in JPG, PNG format, you can take it with your cell phone or laptop, ensure that it is completely clear</li><li>Articles of association in pdf format</li><li>Act of change of powers, this applies in case your company has suffered any change of legal representative</li><li>Proof of fiscal domicile in pdf, recent not more than 3 months, can be a water, electricity or phone bill</li><li>Tax certificate of your business, which you can generate on the SAT portal</li><li>FIEL (digital signature of your business) have your .KEY .CER files and password at hand</li></ul><p>If you are a sole proprietorship with business activit</p><ul><li>CURP ID</li><li>Front photo of the  INE card  of the legal representative, it must be in JPG, PNG format, you can take it with your cell phone or laptop, it only ensures that it is completely clear</li><li>Photo of back of the INE card of the legal representative, it must be in JPG, PNG format, you can take it with your cell phone or laptop, just ensure that it is completely clear</li><li>Proof of fiscal domicile in pdf, recent not more than 3 months, can be a water, electricity or phone bill</li></ul>",
        categories: ["onboarding"]
    },
    {
        title: "What information do I need from my business?",
        description: "<p>Business address, Tax ID RFC and phone number of your company</p>",
        categories: ["onboarding"]
    }
];

const inputAction = {
    label: 'Claim your page',
    prompt: 'evva.community/yourbusinessname'
};

const Donation = () => {
  const appState = useSelector(state => state.appReducer);
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(['consent']);

  const launchFormHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });

    const modal = {
      display: true,
      content: <LaunchForm lang={appState.lang} title="Thank you for your interest!" subtitle="Access by invitation only, register to request your access." amplitude={appState.amplitude} amplitudeClickEvent="PreSignUpCreated"/>
    };

    dispatch(ACTIONS.showModal(modal))
  };

  const termsUseHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    dispatch(ACTIONS.showModal({display: true, content: tcPdf }))
  };

  const privacyPolicyHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    dispatch(ACTIONS.showModal({display: true, content: pcPdf }))
  };

  const cookieHandler = () => {
    setCookie("consent", "dismiss", { path: '/' });
  };

  const closeModal = () => dispatch(ACTIONS.hideModal());
  
  const categoryFAQsHandler = (id) => {
      const { categoryFAQs } = appState;
      const categoryId = categoryFAQs.find(item => item === id);
      
      if (categoryId) {
        const index = categoryFAQs.indexOf(categoryId);
          
        if (index > -1 && categoryFAQs.length > 1) {
            categoryFAQs.splice(index, 1);
        }

        } else {
            categoryFAQs[0] = id
        }

        dispatch(ACTIONS.setCategoryFAQs(categoryFAQs));
    };


  return (
    <Layout lang={appState.lang} privacyPolicyAction={privacyPolicyHandler} termsUseHandlerAction={termsUseHandler}>
        <SEO lang={appState.lang} page="Funding" title="Funding & Donate" 
        description="SMEs and Startups, Freelancers say goodbye to paperwork and long queues at the branch. With EVVA get your digital business account that makes your money grow, 100% online."/>
        {appState.modal.display && <Modal content={appState.modal.content} closeModal={closeModal}/>}
        {!cookies.consent &&
            <OptOut text="This websites uses cookies to ensure that you get the best experience on our website." 
            privacyPolicyAction={privacyPolicyHandler} privacyPolicyLabel="See Privacy Policy" cookieAction={cookieHandler}/>
        }
        <MainSection title="#TogetherSronger" description="<h3>Small businesses affected by COVID-19 are fighting for survival. Together we can make a change.</h3>"  
        position="left" image="hero_donation.jpg" actionLabel="Be a contributor" action={launchFormHandler} amplitude={appState.amplitude} amplitudeClickEvent="ClickBeAContributor">
            <AppBar pathname={appState.path} />
        </MainSection>
        <PageSection title="Small businesses affected by COVID-19 are fighting for survival">
            <img src="../../small-businesses.jpg" width="100%" alt="Small Businneses"/>
        </PageSection>
        <PageSection maxColumns="3" maxWidth={contentMaxWidth}>
            <Card
                imageWidth="154px" 
                imageHeight="154px"
                image="small_businesses_93.2.svg"
                description="<p><strong>SMEs had to temporarily</strong> stop operations with a significant income reduction</p>"
                textAlign="center"
            />
            <Card
                imageWidth="154px" 
                imageHeight="154px"
                image="small_businesses_92.2.svg"
                description="<p>SMEs <strong>has not</strong> received any kind of support, since the first wave</p>"
                textAlign="center"
            />
            <Card
                imageWidth="154px" 
                imageHeight="154px"
                image="small_businesses_46.9.svg"
                description="<p>SMEs <strong>will suffer</strong> severe impacts for the next 6 months. There worst is to come...</p>"
                textAlign="center"
            />
        </PageSection>
        <PageSection maxColumns="3" title="Introducing EVVA SME Community Fund" description="A fund powered by the community in order to support small businesses affected by COVID-19">
            <div>
                <Card
                    imageWidth="270px"
                    imageHeight="196px"
                    image="donate-fees.png"
                    textAlign="center"
                />
                <List title="Join EVVA & donate" listing={joinEvvaDonate}></List>
            </div>
            <div>
                <Card
                    imageWidth="220px"
                    imageHeight="196px"
                    image="reach-existing-customer-icon.png"
                    textAlign="center"
                />
                <List title="Share" listing={share}></List>
            </div>
            <div>
                <Card
                    imageWidth="275px"
                    imageHeight="200px"
                    image="donate-reward.png"
                    textAlign="center"
                />
                <List title="Get rewarded" listing={rewarded}></List>
            </div>
        </PageSection>
        <PageSection title="How it works" maxWidth={contentMaxWidth}>
            <Card
                title="A digital account with purpose"
                description="<p>When you open an account and save. 25% of the management fee will be donated by EVVA to the either of the community fund or the business of their preference</p>"
                descriptionColor="#363636"
                image="wallet-reward-1.png"
                imageWidth="420px"
                imageHeight="320px"
                marginTopContent="70px"
                horizontal
                padding
            />
            <Card
                title="As a contributor you earn and get rewarded"
                description="<p>Our digital account is a low risk investment which generate a daily yield, where you can earn up 6% yearly, plus your will receive rewards from the SMEs you contribute </p>"
                descriptionColor="#363636"
                image="wallet-reward-v2.png"
                actionLabel="Be a contributor"
                action={launchFormHandler}
                imageWidth="420px"
                imageHeight="320px"
                marginTopContent="70px"
                reverseDirection
                horizontal
                padding
            />
            <Card
                title="As a business you get donations, reward and engage"
                description="<p>With our digital business account  you can received donation, and also earn up 6% year, in addition you can reward and engage with your contributors</p>"
                descriptionColor="#363636"
                image="wallet-reward-3.png"
                actionLabel="Join as business"
                action={launchFormHandler}
                actionInput={inputAction}
                imageWidth="420px"
                imageHeight="370px"
                marginTopContent="60px"
                horizontal
                padding
            />  
        </PageSection>
        <ImageSection
            note="Prepaid debit card"
            title="Get the Evva SME Fund card"
            description="<p>Collect rewards, plus 25% of the interchange fee profits will be also donated to the community fund or the business of their preference</p>"
            position="right"
            image="evva-cards-v2.png"
            maxWidth={contentMaxWidth}
            bgColor="#E1E6EC"
            actionLabel="Get it now"
            action={launchFormHandler}
        />
        <PageSection title="Invite your favourite local businesses to participate" maxWidth={contentMaxWidth}>
            <DonateForm
                image="send-letter.svg"
                imageQr="qr_invite.png"
                actionLabel="Invite"
                action={launchFormHandler}
            />
        </PageSection>
        <PageSection title="See the impact you made" maxWidth={contentMaxWidth}>
            <img src="../../donate_impact.jpg" alt="See the impact you made" width="100%"/>
        </PageSection>
        <PageSection title="Frequently asked questions" maxWidth={contentMaxWidth}>
            <FAQs items={dataFAQs} filters={dataFilters} categoryFAQsHandler={categoryFAQsHandler} categoryFAQs={appState.categoryFAQs}/>
        </PageSection>
        <SecondarySection
            title="Together we grow"
            shortDescription="From Entrepreneurs to entrepreneurs, we are people like you who understand your needs. Our ultimate goal is to let you focus on what really matters ... Your business."
            position="center"
            image="evva-together-donate.jpg"
            actionLabel="Be a contributor"
            action={launchFormHandler}
            amplitude={appState.amplitude}
            amplitudeClickEvent="ClickBeAContributor"
        />
    </Layout>
  )
};

export default Donation