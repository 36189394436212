import React, { useState } from "react"
import styles from "./donateForm.module.scss"
import Input from "../input/input"
import Button from "../button/button"

const FormDonate = ({image, imageQr, action, actionLabel, amplitude, amplitudeClickEvent}) => {
    const [formData, setFormData] = useState({
        businessName: "",
        businessMessage: ""
    });
    
    const changeInputHandler = (e) => {
        const {name, value, checked, type} = e.target;
        setFormData({...formData, [name]: type === "checkbox" ? checked : value})
    };


    const onClickHandler = () => {
        if(amplitude && amplitudeClickEvent) {
            amplitude.getInstance().logEvent(amplitudeClickEvent)
        }

        action()
    };

    return (
        <div className={styles["donate-form"]}>
            {image &&
            <div className={styles["donate-form_image"]} style={{backgroundImage: `url(../../${image})`}} />
            }
            {actionLabel && action &&
            <div className={styles["donate-form_content"]}>
                <Input type="text" name="businessName" value={formData.businessName} prompt="Business name" onChange={changeInputHandler}/>
                <Input type="text" name="businessMessage" value={formData.businessMessage} prompt="Message to the business" onChange={changeInputHandler}/>
                <Button text={actionLabel} onClick={onClickHandler}/>
            </div>
            }
            {imageQr &&
            <div className={styles["donate-form_qr"]}>
                <h4>Cant find the business?</h4>
                <p>Bring this QR code to the store and ask them to contact us.</p>
                <div className={styles["donate-form_qr_code"]} style={{backgroundImage: `url(../../${imageQr})`}}/>
            </div>
            }
        </div>
    )
};

export default FormDonate;