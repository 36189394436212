import React from 'react';
import styles from "./list.module.scss"

const List = ({title, listing}) => (
    <div className={styles["list"]}>
        <h3>{title}</h3>
        <ol>
            {listing.map(list => (
                <li key={list.id}>{list.label}</li>
            ))}
        </ol>
    </div>
);

export default List;